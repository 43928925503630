// extracted by mini-css-extract-plugin
var _1 = "cCOjS3BavV";
var _2 = "YrNDvtl4ws";
var _3 = "ucRi2nrYaG";
var _4 = "rNhWySw_ze";
var _5 = "SvddFY93Lq";
var _6 = "sv5jvIVfIT";
var _7 = "TML92adgLy";
var _8 = "v5pXUrzkbt";
export { _1 as "sizeFill", _2 as "sizeFillAlt", _3 as "sizeFit", _4 as "sizeFull", _5 as "width10", _6 as "width16", _7 as "width24", _8 as "width32" }
